<template>
  <gf-base>
    <gf-card title="Companies" subtitle="List of Company">
      <template #toolbar>
        <el-dropdown trigger="click" @command="newCompanies">
          <gf-button type="primary">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <circle fill="#000000" cx="9" cy="15" r="6"/>
                    <path d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z" fill="#000000" opacity="0.3"/>
                  </g>
                </svg>
              </span>
            </template>
            New Record
          </gf-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="customer">New Customer</el-dropdown-item>
            <el-dropdown-item command="supplier">New Supplier</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <template #header>
        <el-input style="width: 250px" class="mr-4" size="medium" prefix-icon="el-icon-search" placeholder="Search by name or company code ..." v-model="search"></el-input>
        <span class="f-13 mr-2">Status :</span>
        <el-select size="medium" class="mr-4" v-model="status" @change="statusChange">
          <el-option :value="null" label="All"></el-option>
          <el-option :value="0" label="Inactive"></el-option>
          <el-option :value="1" label="Active"></el-option>
        </el-select>
        <span class="f-13 mr-2">Type :</span>
        <el-select size="medium" v-model="type" @change="typeChange">
          <el-option :value="null" label="All"></el-option>
          <el-option :value="1" label="Business Customer"></el-option>
          <el-option :value="2" label="Consumer"></el-option>
          <el-option :value="3" label="Supplier"></el-option>
        </el-select>
      </template>
      <el-dialog title="Bulk Assign to Customer Group" :visible.sync="assignModalVisible" append-to-body v-loading="assignModalLoading" :close-on-click-modal="false">
        <gf-form>
          <template #alert>
            <gf-alert>
              <template #icon>
                <span class="svg-icon svg-icon-primary svg-icon-md">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24"/>
                      <path d="M6,7 C7.1045695,7 8,6.1045695 8,5 C8,3.8954305 7.1045695,3 6,3 C4.8954305,3 4,3.8954305 4,5 C4,6.1045695 4.8954305,7 6,7 Z M6,9 C3.790861,9 2,7.209139 2,5 C2,2.790861 3.790861,1 6,1 C8.209139,1 10,2.790861 10,5 C10,7.209139 8.209139,9 6,9 Z" fill="#000000" fill-rule="nonzero"/>
                      <path d="M7,11.4648712 L7,17 C7,18.1045695 7.8954305,19 9,19 L15,19 L15,21 L9,21 C6.790861,21 5,19.209139 5,17 L5,8 L5,7 L7,7 L7,8 C7,9.1045695 7.8954305,10 9,10 L15,10 L15,12 L9,12 C8.27142571,12 7.58834673,11.8052114 7,11.4648712 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                      <path d="M18,22 C19.1045695,22 20,21.1045695 20,20 C20,18.8954305 19.1045695,18 18,18 C16.8954305,18 16,18.8954305 16,20 C16,21.1045695 16.8954305,22 18,22 Z M18,24 C15.790861,24 14,22.209139 14,20 C14,17.790861 15.790861,16 18,16 C20.209139,16 22,17.790861 22,20 C22,22.209139 20.209139,24 18,24 Z" fill="#000000" fill-rule="nonzero"/>
                      <path d="M18,13 C19.1045695,13 20,12.1045695 20,11 C20,9.8954305 19.1045695,9 18,9 C16.8954305,9 16,9.8954305 16,11 C16,12.1045695 16.8954305,13 18,13 Z M18,15 C15.790861,15 14,13.209139 14,11 C14,8.790861 15.790861,7 18,7 C20.209139,7 22,8.790861 22,11 C22,13.209139 20.209139,15 18,15 Z" fill="#000000" fill-rule="nonzero"/>
                    </g>
                  </svg>
                </span>
              </template>
              Only Consumers and Business Customers can be assigned to a Customer Group. {{ selected.filter(s => s.type === 3).length }} of the companies selected will be skipped.
            </gf-alert>
          </template>
          <el-form size="small" label-width="170px">
            <el-form-item label="Customer Group">
              <el-autocomplete style="width: 100%" v-model="searchCg" :fetch-suggestions="getCustomerGroups" @select="selectCustomerGroup"></el-autocomplete>
            </el-form-item>
            <el-form-item label="Default Price List">
              <el-input :value="selectedCustomerGroup.price_list.name" disabled></el-input>
            </el-form-item>
            <el-form-item label="Default Discount">
              <el-input v-model="selectedCustomerGroup.discount_rate" disabled>
                <template slot="append"><strong>%</strong></template>
              </el-input>
            </el-form-item>
            <el-form-item label="Default Payment Term">
              <el-input :value="selectedCustomerGroup.payment_term.name" disabled></el-input>
            </el-form-item>
          </el-form>
          <template #footer>
            <gf-button type="secondary" @click="assignModalVisible = false">
              <template slot="icon">
                <span class="svg-icon svg-icon-sm">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                        <rect x="0" y="7" width="16" height="2" rx="1"/>
                        <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                      </g>
                    </g>
                  </svg>
                </span>
              </template>
              Close
            </gf-button>
            <gf-button type="primary" @click="assignCompanies">
              <template slot="icon">
                <span class="svg-icon svg-icon-white svg-icon-sm">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1"/>
                      <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) " x="4" y="11" width="16" height="2" rx="1"/>
                    </g>
                  </svg>
                </span>
              </template>
              Assign
            </gf-button>
          </template>
        </gf-form>
      </el-dialog>
      <el-dialog title="Bulk Unassign from Customer Group" :visible.sync="unassignModalVisible" append-to-body v-loading="unassignModalLoading" :close-on-click-modal="false">
        <gf-alert>
          <template #icon>
            <span class="svg-icon svg-icon-warning svg-icon-xxl">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>
                  <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1"/>
                  <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1"/>
                </g>
              </svg>
            </span>
          </template>
          You are trying to remove {{ this.selected.filter(f => f.type !== 3).length }} companies from their customer group. Are you sure?
        </gf-alert>
        <template #footer>
          <gf-button type="secondary" class="mr-2" @click="unassignModalVisible = false">
            <template slot="icon">
              <span class="svg-icon svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                      <rect x="0" y="7" width="16" height="2" rx="1"/>
                      <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                    </g>
                  </g>
                </svg>
              </span>
            </template>
            Close
          </gf-button>
          <gf-button type="warning" @click="unassignCompanies">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1"/>
                  </g>
                </svg>
              </span>
            </template>
            Unassign
          </gf-button>
        </template>
      </el-dialog>
      <el-dialog title="Set to Active" :visible.sync="activeModalVisible" append-to-body v-loading="activeModalLoading" :close-on-click-modal="false">
        <gf-alert>
          <template #icon>
            <span class="svg-icon svg-icon-primary svg-icon-xxl">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>
                  <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1"/>
                  <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1"/>
                </g>
              </svg>
            </span>
          </template>
          You are trying to set the status of {{ this.selected.length }} companies to Active. Are you sure?
        </gf-alert>
        <template #footer>
          <gf-button type="secondary" class="mr-2" @click="activeModalVisible = false">
            <template slot="icon">
              <span class="svg-icon svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                      <rect x="0" y="7" width="16" height="2" rx="1"/>
                      <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                    </g>
                  </g>
                </svg>
              </span>
            </template>
            Close
          </gf-button>
          <gf-button type="primary" @click="setActive">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>
                    <path d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z" fill="#000000" fill-rule="nonzero"/>
                  </g>
                </svg>
              </span>
            </template>
            Confirm
          </gf-button>
        </template>
      </el-dialog>
      <el-dialog title="Set to Inactive" :visible.sync="inactiveModalVisible" append-to-body v-loading="inactiveModalLoading" :close-on-click-modal="false">
        <gf-alert>
          <template #icon>
            <span class="svg-icon svg-icon-warning svg-icon-xxl">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>
                  <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1"/>
                  <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1"/>
                </g>
              </svg>
            </span>
          </template>
          You are trying to set the status of {{ this.selected.length }} companies to Inactive. Are you sure?
        </gf-alert>
        <template #footer>
          <gf-button type="secondary" class="mr-2" @click="inactiveModalVisible = false">
            <template slot="icon">
              <span class="svg-icon svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                      <rect x="0" y="7" width="16" height="2" rx="1"/>
                      <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                    </g>
                  </g>
                </svg>
              </span>
            </template>
            Close
          </gf-button>
          <gf-button type="warning" @click="setInactive">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>
                    <path d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z" fill="#000000" fill-rule="nonzero"/>
                  </g>
                </svg>
              </span>
            </template>
            Confirm
          </gf-button>
        </template>
      </el-dialog>
      <el-dialog title="Delete Companies" :visible.sync="deleteModalVisible" append-to-body v-loading="deleteModalLoading" :close-on-click-modal="false">
        <gf-alert>
          <template #icon>
            <span class="svg-icon svg-icon-danger svg-icon-xxl">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <path d="M11.1669899,4.49941818 L2.82535718,19.5143571 C2.557144,19.9971408 2.7310878,20.6059441 3.21387153,20.8741573 C3.36242953,20.9566895 3.52957021,21 3.69951446,21 L21.2169432,21 C21.7692279,21 22.2169432,20.5522847 22.2169432,20 C22.2169432,19.8159952 22.1661743,19.6355579 22.070225,19.47855 L12.894429,4.4636111 C12.6064401,3.99235656 11.9909517,3.84379039 11.5196972,4.13177928 C11.3723594,4.22181902 11.2508468,4.34847583 11.1669899,4.49941818 Z" fill="#000000" opacity="0.3"/>
                  <rect fill="#000000" x="11" y="9" width="2" height="7" rx="1"/>
                  <rect fill="#000000" x="11" y="17" width="2" height="2" rx="1"/>
                </g>
              </svg>
            </span>
          </template>
          You are trying to delete {{ this.selected.length }} companies. This action cannot be undone. Are you sure?
        </gf-alert>
        <template #footer>
          <gf-button type="secondary" class="mr-2" @click="deleteModalVisible = false">
            <template slot="icon">
              <span class="svg-icon svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                      <rect x="0" y="7" width="16" height="2" rx="1"/>
                      <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                    </g>
                  </g>
                </svg>
              </span>
            </template>
            Close
          </gf-button>
          <gf-button type="danger" @click="remove">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fill-rule="nonzero"/>
                    <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>
                  </g>
                </svg>
              </span>
            </template>
            Delete
          </gf-button>
        </template>
      </el-dialog>
      <gf-table :data="companies" :selection-command="selectionCommand" :column-filter="columnFilter" @command="command" @row-click="readCompany" v-loading="loading">
        <el-table-column type="selection">
        </el-table-column>
        <el-table-column v-if="columnFilter[0].show" label="Name" min-width="200" prop="name">
          <template #default="slot">
            {{ $StringFormat(slot.row.name, 20) }}
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[1].show" label="Company Code" min-width="200" prop="code">
        </el-table-column>
        <el-table-column v-if="columnFilter[2].show" label="Company Type" min-width="200">
          <template #default="slot">
            <span v-if="slot.row.type === 1">Business Customer</span>
            <span v-else-if="slot.row.type === 2">Consumer</span>
            <span v-else-if="slot.row.type === 3">Supplier</span>
            <span v-else>None</span>
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[3].show" label="Customer Group" min-width="200" prop="customer_group.name">
        </el-table-column>
        <el-table-column v-if="columnFilter[4].show" label="Email Address" min-width="200"  prop="email">
        </el-table-column>
        <el-table-column v-if="columnFilter[5].show" label="Phone Number" min-width="200"  prop="phone_number">
        </el-table-column>
        <el-table-column v-if="columnFilter[6].show" label="Fax" min-width="200"  prop="fax_number">
        </el-table-column>
        <el-table-column v-if="columnFilter[7].show" label="Website" min-width="200"  prop="website">
        </el-table-column>
        <el-table-column v-if="columnFilter[8].show" label="Status" min-width="120">
          <template #default="slot">
            <gf-label inline pill light type="primary" v-if="slot.row.is_active">Active</gf-label>
            <gf-label inline pill light type="dark" v-else>Inactive</gf-label>
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[9].show" label="Created" min-width="150"  prop="created_at">
          <template #default="slot">
            {{ $DateFormat(slot.row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[10].show" label="Last Updated" min-width="150" prop="updated_at">
          <template #default="slot">
            {{ $DateFormat(slot.row.updated_at) }}
          </template>
        </el-table-column>
      </gf-table>
      <template #footer>
        <gf-pagination :pagination="pagination" @change="getCompanies"></gf-pagination>
      </template>
    </gf-card>
  </gf-base>
</template>

<script>
// Services
import CompanyService from '@/services/v1/Company'
import CustomerGroupService from '@/services/v1/CustomerGroup'

// Lodash
import { debounce } from 'lodash'

export default {
  data () {
    return {
      columnFilter: [
        { label: 'Name', show: true },
        { label: 'Company Code', show: true },
        { label: 'Company Type', show: true },
        { label: 'Customer Group', show: true },
        { label: 'Email Address', show: true },
        { label: 'Phone Number', show: true },
        { label: 'Fax', show: true },
        { label: 'Website', show: true },
        { label: 'Status', show: true },
        { label: 'Created', show: true },
        { label: 'Last Updated', show: true }
      ],
      selectionCommand: [
        { label: 'Assign to Customer Group', command: 'assign' },
        { label: 'Unassign to Customer Group', command: 'unassign' },
        { label: 'Set to Active', command: 'active', divided: true },
        { label: 'Set to Inactive', command: 'inactive' },
        { label: 'Delete All Selected', command: 'delete' }
      ],
      selected: [],

      // filter
      search: '',
      status: null,
      type: null,
      filter: {},

      // cg filter
      searchCg: '',

      // Modal
      assignModalVisible: false,
      assignModalLoading: false,
      unassignModalVisible: false,
      unassignModalLoading: false,
      activeModalVisible: false,
      activeModalLoading: false,
      inactiveModalVisible: false,
      inactiveModalLoading: false,
      deleteModalVisible: false,
      deleteModalLoading: false,

      selectedCustomerGroup: {
        id: null,
        name: null,
        price_list: {
          name: null
        },
        payment_term: {
          name: null
        },
        discount_rate: null
      },
      customerGroups: [],

      // main
      companies: [],
      loading: false,

      // Pagination
      pagination: {
        total: 0,
        page: {
          size: 25,
          number: 1
        }
      }
    }
  },
  watch: {
    search: debounce(function (value) {
      if (value === '') {
        delete this.filter.q
      } else {
        this.filter.q = {
          like: value
        }
      }

      // Reset to page 1
      this.pagination.page.number = 1
      this.getCompanies()
    }, 400)
  },
  methods: {
    // Filter
    statusChange () {
      // Reset to page 1
      this.pagination.page.number = 1

      if (this.status !== null) {
        this.filter['cus.is_active'] = { '=': this.status }
        this.getCompanies()
        return
      }

      delete this.filter['cus.is_active']
      this.getCompanies()
    },
    typeChange () {
      // Reset to page 1
      this.pagination.page.number = 1

      if (this.type !== null) {
        this.filter['cus.type'] = { '=': this.type }
        this.getCompanies()
        return
      }

      delete this.filter['cus.type']
      this.getCompanies()
    },

    // autocomplete
    selectCustomerGroup (customerGroup) {
      this.selectedCustomerGroup = customerGroup.detail
    },

    // Fetch
    async getCompanies () {
      try {
        this.loading = true
        const cmService = new CompanyService()
        const result = await cmService.list(this.pagination.page, null, this.filter, null)
        this.companies = result.data.rows

        if (result.data.count === 0 && result.data.rows.length !== 0) {
        } else this.pagination.total = result.data.count
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    },
    async getCustomerGroups (query, cb) {
      try {
        const out = []
        const filter = {}

        if (query === '' || !query) {
          delete filter.q
        } else {
          filter.q = {
            like: query
          }
        }

        const cgService = new CustomerGroupService()
        const result = await cgService.list(null, null, filter)

        for (let i = 0; i < result.data.rows.length; i++) {
          const cg = result.data.rows[i]
          out.push({
            value: cg.name,
            detail: cg
          })
        }

        cb(out)
      } catch (error) {
        this.$Error(error)
      }
    },

    // handler
    command (event) {
      this.selected = event.selection
      if (event.cmd === 'assign') {
        this.assignModalVisible = true
        return
      }

      if (event.cmd === 'unassign') {
        this.unassignModalVisible = true
        return
      }

      if (event.cmd === 'active') {
        this.activeModalVisible = true
        return
      }

      if (event.cmd === 'inactive') {
        this.inactiveModalVisible = true
        return
      }

      if (event.cmd === 'delete') {
        this.deleteModalVisible = true
      }
    },
    newCompanies (command) {
      this.$router.push({ name: 'company-new', params: { type: command } })
    },
    readCompany (row) {
      this.$router.push({ name: 'company-view', params: { id: row.id } })
    },
    async assignCompanies () {
      try {
        if (!this.selectedCustomerGroup.id) {
          this.$notify.info({
            title: 'Assign Customer Group',
            message: 'No selected Customer Group.'
          })
          return
        }

        this.assignModalLoading = true
        const customer = this.selected.filter(f => f.type !== 3)
        for (let i = 0; i < customer.length; i++) {
          const sl = customer[i]

          for (const key in sl) {
            if (key === 'id' || key === 'setting') continue
            delete sl[key]
          }
        }

        const cgService = new CustomerGroupService(this.selectedCustomerGroup.id)
        await cgService.assign(customer)
        this.$message.success(`Successfully added ${customer.length} companies to ${this.selectedCustomerGroup.name}`)

        // clear
        this.searchCg = ''
        this.selectedCustomerGroup = {
          id: null,
          name: null,
          price_list: {
            name: null
          },
          payment_term: {
            name: null
          },
          discount_rate: null
        }

        this.assignModalVisible = false
        this.getCompanies()
      } catch (error) {
        this.$Error(error)
      } finally {
        this.assignModalLoading = false
      }
    },
    async unassignCompanies () {
      try {
        this.unassignModalLoading = true
        const customer = this.selected.filter(f => f.type !== 3)
        for (let i = 0; i < customer.length; i++) {
          const sl = customer[i]

          for (const key in sl) {
            if (key === 'id' || key === 'setting') continue
            delete sl[key]
          }
        }

        const cgService = new CustomerGroupService()
        await cgService.unassign(customer)
        this.$message.success(`Successfully removed ${customer.length} companies from their Customer Group`)
        this.getCompanies()

        this.unassignModalVisible = false
      } catch (error) {
        this.$Error(error)
      } finally {
        this.unassignModalLoading = false
      }
    },
    async setActive () {
      try {
        this.activeModalLoading = true
        const customersId = []
        for (let i = 0; i < this.selected.length; i++) {
          const sl = this.selected[i]
          customersId.push(sl.id)
        }

        const cmService = new CompanyService()
        await cmService.setActive(customersId)
        this.$message.success(`${customersId.length} companies were successfully set to active`)

        this.activeModalVisible = false
        this.getCompanies()
      } catch (error) {
        this.$Error(error)
      } finally {
        this.activeModalLoading = false
      }
    },
    async setInactive () {
      try {
        this.inactiveModalLoading = true
        const customersId = []
        for (let i = 0; i < this.selected.length; i++) {
          const sl = this.selected[i]
          customersId.push(sl.id)
        }

        const cmService = new CompanyService()
        await cmService.setInactive(customersId)
        this.$message.success(`${customersId.length} companies were successfully set to active`)

        this.inactiveModalVisible = false
        this.getCompanies()
      } catch (error) {
        this.$Error(error)
      } finally {
        this.inactiveModalLoading = false
      }
    },
    async remove () {
      try {
        this.deleteModalLoading = true
        const customersId = []
        for (let i = 0; i < this.selected.length; i++) {
          const sl = this.selected[i]
          customersId.push(sl.id)
        }

        const cmService = new CompanyService()
        await cmService.delMultiple(customersId)
        this.$message.success(`${customersId.length} companies were successfully deleted`)

        this.deleteModalVisible = false
        this.getCompanies()
      } catch (error) {
        this.$Error(error)
      } finally {
        this.deleteModalLoading = false
      }
    }
  },
  mounted () {
    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Relationship', route: '' },
      { title: 'Companies' }
    ])

    this.getCompanies()
  }
}
</script>
