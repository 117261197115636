import Service from '../Service'

export default class extends Service {
  constructor (id) {
    const url = ['relationship', 'customer-groups']
    if (id) {
      url.push(id)
    }
    super(url)
  }

  assign (data) {
    return this.post(data, '/assign')
  }

  unassign (data) {
    return this.post(data, '/unassign')
  }
}
